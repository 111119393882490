import * as React from 'react';
import Banner from '../Section/Banner';

export interface IImpressumProps {
}

export interface IImpressumState {
}

export default class Impressum extends React.Component<IImpressumProps, IImpressumState> {
  constructor(props: IImpressumProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className='container pb-6'>
                <Banner isHome={false} pageName="Impressum" color="white"/>
                <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
        <div className="max-w-4xl w-full bg-white rounded-lg shadow-md p-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-6">Impressum</h1>
          <div className="space-y-6 text-gray-700">
            {/* Company Information */}
            <div>
              <h2 className="text-lg font-semibold">Firma:</h2>
              <p>Mannus-Installateur</p>
            </div>
            <div>
              <h2 className="text-lg font-semibold">UID-Nummer:</h2>
              <p>ATU81227414</p>
            </div>
            <div>
              <h2 className="text-lg font-semibold">GISA-Zahl:</h2>
              <p>37361945</p>
            </div>
            <div>
              <h2 className="text-lg font-semibold">Adresse:</h2>
              <p>Kaiserstraße 48, 1070 Wien</p>
            </div>
            <div>
              <h2 className="text-lg font-semibold">Inhaber:</h2>
              <p>Subhi SYED ISSA</p>
            </div>
            <div>
              <h2 className="text-lg font-semibold">Telefonnummer:</h2>
              <p>0660 2265474</p>
            </div>
            <div>
              <h2 className="text-lg font-semibold">E-Mail:</h2>
              <p>
                <a
                  href="mailto:info@mannus-installateur.at"
                  className="text-blue-500 underline"
                >
                  info@mannus-installateur.at
                </a>
              </p>
            </div>

            {/* Additional Legal Information */}
            <div>
              <h2 className="text-lg font-semibold">Handelsregister:</h2>
              <p>Eintragung im Firmenbuch, Landesgericht Wien</p>
            </div>
            <div>
              <h2 className="text-lg font-semibold">Rechtsform:</h2>
              <p>Einzelunternehmen</p>
            </div>
            <div>
              <h2 className="text-lg font-semibold">Aufsichtsbehörde:</h2>
              <p>Magistratisches Bezirksamt für den 7. Bezirk</p>
            </div>

            {/* Disclaimers */}
            <div>
              <h2 className="text-lg font-semibold">Haftungsausschluss:</h2>
              <p>
                Trotz sorgfältiger Prüfung übernehmen wir keine Haftung für die
                Richtigkeit, Vollständigkeit und Aktualität der Inhalte. Für
                den Inhalt externer Links sind ausschließlich deren Betreiber
                verantwortlich.
              </p>
            </div>

            {/* Copyright */}
            <div>
              <h2 className="text-lg font-semibold">Urheberrecht:</h2>
              <p>
                Alle Inhalte dieser Webseite, einschließlich Texte, Bilder und
                Grafiken, unterliegen dem Urheberrecht. Jede unerlaubte
                Verwendung, Vervielfältigung oder Weitergabe ist untersagt.
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
