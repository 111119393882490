import React, { Component } from "react";
import Banner from "../Section/Banner";

class Datenschutz extends Component {
  render() {
    return (
        <div className='container pb-6'>
                <Banner isHome={false} pageName="Impressum" color="white"/>
      <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6">
        <div className="max-w-4xl w-full bg-white rounded-lg shadow-md p-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-6">Datenschutzerklärung</h1>
          <div className="space-y-6 text-gray-700">
            {/* Introduction */}
            <div>
              <p>
                Der Schutz Ihrer persönlichen Daten ist uns ein besonderes
                Anliegen.In dieser Datenschutzerklärung informieren wir Sie über die
                wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer
                Website.
              </p>
            </div>

            {/* Data Storage */}
            <div>
              <h2 className="text-lg font-semibold">1. Speicherung von Daten</h2>
              <p>
                Wenn Sie unsere Website besuchen, wird Ihre IP-Adresse
                erfasst und für die Dauer der Sitzung gespeichert. Dies ist aus
                technischen Gründen erforderlich und stellt ein berechtigtes
                Interesse gemäß Art. 6 Abs. 1 lit. f DSGVO dar. Weitere
                personenbezogene Daten speichern wir nur, wenn Sie uns diese
                freiwillig zur Verfügung stellen, beispielsweise durch das
                Ausfüllen eines Kontaktformulars.
              </p>
            </div>

            {/* Cookies */}
            <div>
              <h2 className="text-lg font-semibold">2. Cookies</h2>
              <p>
                Unsere Website verwendet sogenannte Cookies. Das sind kleine
                Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät
                abgelegt werden. Sie richten keinen Schaden an. Wir nutzen
                Cookies, um unser Angebot nutzerfreundlich zu gestalten. Einige
                Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
                löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten
                Besuch wiederzuerkennen.
              </p>
              <p>
                Wenn Sie dies nicht wünschen, können Sie Ihren Browser so
                einrichten, dass er Sie über das Setzen von Cookies informiert
                und Sie dies nur im Einzelfall erlauben. Bei der Deaktivierung
                von Cookies kann die Funktionalität unserer Website
                eingeschränkt sein.
              </p>
            </div>

            {/* Data Usage */}
            <div>
              <h2 className="text-lg font-semibold">3. Verwendung von Daten</h2>
              <p>
                Die von Ihnen bereitgestellten Daten werden zur Erfüllung des
                Vertrags oder zur Durchführung vorvertraglicher Maßnahmen
                verarbeitet. Ohne diese Daten können wir den Vertrag mit Ihnen
                nicht abschließen. Eine Weitergabe der Daten erfolgt nicht,
                außer an unseren Steuerberater zur Erfüllung unserer
                steuerrechtlichen Verpflichtungen.
              </p>
            </div>

            {/* User Rights */}
            <div>
              <h2 className="text-lg font-semibold">4. Ihre Rechte</h2>
              <p>
                Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung,
                Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und
                Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer
                Daten gegen das Datenschutzrecht verstößt oder Ihre
                datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt
                worden sind, können Sie sich bei der Aufsichtsbehörde
                beschweren. In Österreich ist dies die Datenschutzbehörde.
              </p>
            </div>

            {/* Contact Information */}
            <div>
              <h2 className="text-lg font-semibold">5. Kontakt</h2>
              <p>
                Wenn Sie Fragen zum Datenschutz haben, können Sie uns jederzeit
                kontaktieren:
              </p>
              <ul className="list-disc pl-5">
                <li>Mannus-Installateur</li>
                <li>Kaiserstraße 48, 1070 Wien</li>
                <li>
                  <a
                    href="mailto:info@mannus-installateur.at"
                    className="text-blue-500 underline"
                  >
                    info@mannus-installateur.at
                  </a>
                </li>
                <li>0660 2265474</li>
              </ul>
            </div>
          </div>
          <div className="mt-8 text-sm text-gray-600">
          <a href="https://www.freepik.com/free-photo/young-handsome-man-grey-shirt_12562277.htm#fromView=search&page=1&position=0&uuid=ec84b754-799a-4168-82a4-ef60595beba9">Image by stockking on Freepik</a>

          </div>
        </div>
        
      </div>
      </div>
    );
  }
}

export default Datenschutz;
